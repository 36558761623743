import _ from "lodash";

export const updateObject = (oldObject, updatedProperties) => {
  Object.assign(oldObject, updatedProperties)
}


export const getCurrentDate = (separator = ' ', language = 'en') => {
  const monthNames = ["January", "February", "March", "April", "May", "June",
    "July", "August", "September", "October", "November", "December"
  ];

  const frMonthNames = ["Janvier", "Février", "Mars", "Avril", "Mai", "Juin", "Juillet", "Août", "Septembre", "Octobre", "Novembre ", "Décembre"]
  let newDate = new Date()
  let date = newDate.getDate();
  let month = (language === "fr" ? frMonthNames[newDate.getMonth()] : monthNames[newDate.getMonth()]);
  let year = newDate.getFullYear();
  var hours = newDate.getHours();
  var minutes = newDate.getMinutes();
  var ampm = hours >= 12 ? 'pm' : 'am';
  hours = hours % 12;
  hours = hours ? hours : 12; // the hour '0' should be '12'
  minutes = minutes < 10 ? '0' + minutes : minutes;
  var strTime = hours + ':' + minutes + ' ' + ampm;
  return `${month < 10 ? `0${month}` : `${month}`}${separator}${date}, ${year} ${strTime}`
}

export const choosefieldValue = (categoryName) => {
  switch (categoryName) {
    case "Category":
      var fieldName = "product_filters_category";
      break;
    case "Allergies":
      var fieldName = "product_filters_allergies";
      break;
    case "Imbalance":
      var fieldName = "product_filters_imbalance";
      break;
    case "Lifestyle":
      var fieldName = "product_filters_lifestyle";
      break;
    case "SNP":
      var fieldName = "product_filters_snp";
      break;
    case "Delivery Format":
      var fieldName = "product_filters_delivery_format";
      break;
    default:
      var fieldName = fieldName;
  }
  return fieldName;
};

export const chooseFilterValue = (categoryName) => {
  switch (categoryName) {
    case "product_filters_category":
      var filterName = " category";
      break;
    case "product_filters_allergies":
      var filterName = " allergies";
      break;
    case "product_filters_imbalance":
      var filterName = " imbalance";
      break;
    case "product_filters_lifestyle":
      var filterName = " lifestyle";
      break;
    case "product_filters_snp":
      var filterName = " snp";
      break;
    case "product_filters_delivery_format":
      var filterName = " delivery format";
      break;
    default:
      var filterName = filterName;
  }
  return filterName;
};


export const chooseDosaValue = (dose_frequancy) => {

  let doseFreqc = dose_frequancy
  if(_.isNull(dose_frequancy)) {
    doseFreqc = ""
  }

  switch (doseFreqc.replace(/\D/g, "")) {
    case "":
      var frequancy_count = 1;
      break;
    case "12":
      var frequancy_count = 2;
      break;
    case "13":
      var frequancy_count = 3;
      break;
    case "14":
      var frequancy_count = 4;
      break;
    default:
      var frequancy_count = dose_frequancy.replace(/\D/g, "").split("")[0];
  }
  return frequancy_count
}

export const convertDiscountPrice = (price, discountValue) => {
  var discountPrice = (price * discountValue) / 100;

  let finalDiscount = (price - discountPrice).toFixed(2);
  return finalDiscount
}

export const isMobileView = (function () {
  // return true;
  return window.innerWidth < 612
}())

export const diffDays = (oldDate, currentDate) => {
  console.log(oldDate, currentDate, "-=-=-=-=-=-");
  const date1 = new Date(oldDate);
  const date2 = new Date(currentDate);
  const diffTime = Math.abs(date2 - date1);
  const diffDays = Math.ceil(diffTime / (1000 * 60 * 60 * 24));
  return diffDays
}

export const setRialtoApiUrl = (clientName) => {
  switch (clientName) {
    case "purepro":
      var url = process.env.REACT_APP_PUREPRO_RIALTO_API_URL
      break;
    case "pg3":
      var url = process.env.REACT_APP_PG3_RIALTO_API_URL;
      break;
    default:
      var url = process.env.REACT_APP_RIALTO_API_URL;
  }
  return url
}